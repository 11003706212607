import { FC, Dispatch, SetStateAction } from 'react';
import { useTheme } from 'styled-components';
import { TScamQuestions } from 'types';
import StaleInputRadioNew from 'components/shared/StaleInputRadioNew/StaleInputRadioNew';
import { Col } from 'components/shared/Col/Col';
import { Paragraph, Title } from 'components/shared/Typography/Typography';
import Icon from 'components/shared/Icon/Icon';
import { SCAM_QUESTIONS_DATA } from '../../consts';
import { SCAM_HELP_URL } from 'variables';
import Link from 'components/shared/Link/Link';
import { Row } from 'components/shared/Row/Row';
import Button from 'components/shared/Button/Button';
import Popup from 'components/shared/Popup/Popup';

interface OwnProps {
  setScamAlertAnswer: Dispatch<SetStateAction<TScamQuestions | undefined>>;
  scamAlertAnswer?: string;
  onClose: () => void;
}

const ScamAlert: FC<OwnProps> = ({
  setScamAlertAnswer,
  scamAlertAnswer,
  onClose,
}) => {
  const theme = useTheme();

  return (
    <Popup
      width="439px"
      HeaderContent={<Title variant="h3">Scam alert</Title>}
      FooterContent={
        <Button flex={1} onClick={onClose}>
          Close
        </Button>
      }
      onClose={onClose}
    >
      <Row justifyContent="flex-start">
        <Icon fill="red" icon="warning-ico" />
        <Title variant="h4">Take five. Could it be fake?</Title>
      </Row>

      <Paragraph mt>
        Criminals often target email, mobile, and chat communications by
        impersonating suppliers and manipulating businesses into sending funds
        into malicious hands. Always verify who you are sending money to -
        recovering international payments is notoriously hard.
        <Link display="inline-flex" target="_blank" href={SCAM_HELP_URL}>
          Read more.
        </Link>
      </Paragraph>

      <Paragraph mt>
        Which steps did you take to verify payment details for this recipient:
      </Paragraph>

      <Col mt gap={theme.spacing.s}>
        {SCAM_QUESTIONS_DATA.map(({ id, value, name }) => (
          <StaleInputRadioNew
            key={id}
            id={value}
            label={name}
            checked={scamAlertAnswer === value}
            onChange={() => setScamAlertAnswer(value)}
          />
        ))}
      </Col>
    </Popup>
  );
};

export default ScamAlert;
