import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import { getCashflowsPerCurrencyValues } from 'utils/analysis';
import {
  notNullTypeGuard,
  openInNewTab,
  parseIntoShortNumberString,
  roundToPrecision,
} from 'utils';
import { getReduceRiskRatingForHexagons } from '../utils';
import {
  getCurrencyExchangePageLink,
  getPrebookPageLink,
  getRiskSettingsPageLink,
  getRisksPageLink,
} from 'utils/links';
import { DB_DATE_FORMAT, SCHEDULE_MEET_URL } from 'variables';
import {
  useCreatePrebooksPermissionsCheck,
  useUpdateBalancesPermissionsCheck,
} from 'hooks/useSpecificPermissionsCheck';
import { TRiskSettingHedgeRatio } from 'state/user';
import { INetworthValues, TCashflowAtRiskNew } from 'types';
import dayjs from 'dayjs';
import { StyledLink } from 'components/shared/Link/Link.styles';

type TReduceRisksCard = {
  id: number;
  icon: string;
  title: string;
  textOne: string | JSX.Element;
  textTwo?: string | JSX.Element;
  buttonText: string;
  onClick?: () => void;
  showRisk: boolean;
  risk?: number;
  disabled?: boolean;
};

const getHedgeRatioCard = ({
  riskDataPerPeriod,
  hedgeRatioSettings,
  history,
  currency,
  cashflowAtRisk,
  cashflowAtRiskPoSo,
  cashflowAtRiskCustomCashflow,
}: {
  riskDataPerPeriod: TCashflowAtRiskNew[];
  hedgeRatioSettings: TRiskSettingHedgeRatio | undefined;
  history: ReturnType<typeof useHistory>;
  currency: string;
  cashflowAtRisk: number;
  cashflowAtRiskPoSo: number;
  cashflowAtRiskCustomCashflow: number;
}): TReduceRisksCard | null => {
  const riskValue = getReduceRiskRatingForHexagons(
    (cashflowAtRiskPoSo + cashflowAtRiskCustomCashflow) / cashflowAtRisk
  );
  if (!hedgeRatioSettings) {
    return {
      id: 4,
      icon: 'search-ico',
      title: 'Setup your CashFlows Hedging',
      textOne:
        'Future cashflows in foreign currencies, such as sales or purchase orders may contribute to your FX risks.',
      textTwo: (
        <>
          Consider setting up a Cashflows hedging policy, including Hedge
          Ratios. Read more about cashflows hedging{' '}
          <StyledLink
            display="inline"
            href="https://land.hedgeflows.com/cashflow-hedging-guide"
            target="_blank"
          >
            here
          </StyledLink>
          .
        </>
      ),
      buttonText: 'Go to Risk settings',
      onClick: () => history.push(getRiskSettingsPageLink({ step: '1' })),
      showRisk: true,
      risk: riskValue,
    };
  }

  const isHedgeRatioSettingsNotMet = riskDataPerPeriod.find((item) =>
    Object.values(hedgeRatioSettings).find(
      (hedgeRatio) => item.hedgeRatio < hedgeRatio.min
    )
  );
  const isHedgeRatioSettingsMet = riskDataPerPeriod.find((item) =>
    Object.values(hedgeRatioSettings).find(
      (hedgeRatio) => item.hedgeRatio >= hedgeRatio.min
    )
  );

  if (isHedgeRatioSettingsNotMet) {
    return {
      id: 4,
      icon: 'search-ico',
      title: 'Review your Hedge Ratios',
      textOne:
        'Your minimum Hedge Ratio targets are not met. Review your Hedge Ratios and increase your hedges for relevant months.',
      buttonText: 'Review Hedge Ratios',
      onClick: () =>
        history.push(
          getRisksPageLink({
            currency,
            chartContentType: 'hedgeRatio',
          })
        ),
      showRisk: true,
      risk: riskValue,
    };
  } else if (isHedgeRatioSettingsMet) {
    return {
      id: 4,
      icon: 'search-ico',
      title: 'Your minimum target ratios are met',
      textOne:
        'Maintaining hedges at or above the minimum hedge ratio targets is good practice for hedging future cashflows.',
      buttonText: 'Review Hedge Ratios',
      onClick: () => history.push(getRiskSettingsPageLink({ step: '1' })),
      showRisk: true,
      risk: riskValue,
      disabled: true,
    };
  }

  return null;
};

const getBudgetRatesCard = ({
  riskDataPerPeriod,
  history,
  currency,
  cashflowAtRisk,
  cashflowAtRiskPoSo,
  cashflowAtRiskCustomCashflow,
}: {
  riskDataPerPeriod: TCashflowAtRiskNew[];
  history: ReturnType<typeof useHistory>;
  currency: string;
  cashflowAtRisk: number;
  cashflowAtRiskPoSo: number;
  cashflowAtRiskCustomCashflow: number;
}): TReduceRisksCard | null => {
  const anyOfBudgetRatesForFutureMonthsWithCashflowsNotSet = !!riskDataPerPeriod.find(
    (item) =>
      dayjs(item.date, DB_DATE_FORMAT).isAfter(dayjs().endOf('month')) &&
      item.payables + item.receivables !== 0 &&
      (item.budgetRate === null || item.budgetRate === 0)
  );
  const anyOfBudgetRatesForFutureMonthsNotSet = !!riskDataPerPeriod.find(
    (item) =>
      dayjs(item.date, DB_DATE_FORMAT).isAfter(dayjs().endOf('month')) &&
      (item.budgetRate === null || item.budgetRate === 0)
  );
  const riskValue = getReduceRiskRatingForHexagons(
    (cashflowAtRiskPoSo + cashflowAtRiskCustomCashflow) / cashflowAtRisk
  );

  if (anyOfBudgetRatesForFutureMonthsWithCashflowsNotSet) {
    return {
      id: 5,
      icon: 'risk-forecasted-ico',
      title: 'Set up budget rates',
      textOne: (
        <>
          Consider setting up budget rates to help internal planning and
          budgeting. Read more about budget rates{' '}
          <StyledLink
            display="inline"
            href="https://app.hubspot.com/documents/8698300/view/638856454?accessId=a3b26a"
            target="_blank"
          >
            here
          </StyledLink>
          .
        </>
      ),
      buttonText: 'Set up budget rates',
      onClick: () =>
        history.push(
          getRisksPageLink({
            currency,
            chartContentType: 'rates',
            popupType: 'setBudgetRates',
          })
        ),
      showRisk: true,
      risk: riskValue,
    };
  } else if (anyOfBudgetRatesForFutureMonthsNotSet) {
    return {
      id: 5,
      icon: 'risk-forecasted-ico',
      title: 'Stay within Hedge Ratio range',
      textOne:
        'Stay within Min-Max Hedge Ratio target range. If you can lock in exchange rates well above your budget rates, consider hedging closer to your Max target.',
      buttonText: 'Review Hedge Ratios',
      onClick: () =>
        history.push(
          getRisksPageLink({
            currency,
            chartContentType: 'hedgeRatio',
          })
        ),
      showRisk: true,
      risk: riskValue,
    };
  }

  return null;
};

const getCashBalancesCard = ({
  history,
  currency,
  cashflowAtRisk,
  cashflowAtRiskCash,
  balances,
  externalBalances,
  hasUpdateBalancesPermission,
}: {
  history: ReturnType<typeof useHistory>;
  currency: string;
  cashflowAtRisk: number;
  cashflowAtRiskCash: number;
  balances: number;
  externalBalances: number;
  hasUpdateBalancesPermission: boolean;
}) => {
  const isPositiveCfarFromCash = cashflowAtRiskCash > 0;

  return {
    id: 1,
    icon: 'risk-convert-ico',
    title: 'Convert your cash balances',
    textOne: isPositiveCfarFromCash
      ? 'Looking good. Your cash balances are minimal or offsetting your future cashflows.'
      : 'Your account balances in foreign currencies could lose value in Pounds Sterling if exchange rates move.',
    textTwo: isPositiveCfarFromCash
      ? `Your future cashflows will further add to your balance. Convert up to ${currency} ${parseIntoShortNumberString(
          balances + externalBalances
        )} to reduce your risks.`
      : undefined,
    buttonText: 'Reduce balances',
    onClick: () =>
      history.push(
        getCurrencyExchangePageLink({
          predefinedBuyCurrency: currency ?? undefined,
          step: '1',
        })
      ),
    showRisk: true,
    risk: getReduceRiskRatingForHexagons(cashflowAtRiskCash / cashflowAtRisk),
    disabled: !hasUpdateBalancesPermission || !isPositiveCfarFromCash,
  };
};

const useReduceRisks = (cashflowsRisksData: {
  perPeriod: TCashflowAtRiskNew[];
  networthValues: INetworthValues;
}) => {
  const history = useHistory();
  const hasCreatePrebooksPermission = useCreatePrebooksPermissionsCheck();
  const hasUpdateBalancesPermission = useUpdateBalancesPermissionsCheck();
  const { cashflowsRisks } = useStoreState((state) => state.ReferenceDataState);
  const { entityCurrencyCode, userEntity } = useStoreState(
    (state) => state.UserState
  );
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { currency: currencyCode } = useUrlValues('currency');
  const currency = currencyByCode(currencyCode);
  const cashflowRisksByCurrency = currency
    ? cashflowsRisks?.currencyRisk[currency.code]
    : undefined;

  const cards = useMemo(() => {
    if (!cashflowRisksByCurrency) {
      return [];
    }

    const {
      cashflowAtRisk,
      cashflowAtRiskCash,
      cashflowAtRiskPayablesReceivables14,
      cashflowAtRiskPayablesReceivables14Plus,
      cashflowAtRiskPoSo,
      cashflowAtRiskCustomCashflow,
    } = cashflowRisksByCurrency;

    const { balances, externalBalances } = getCashflowsPerCurrencyValues(
      cashflowRisksByCurrency
    );

    const hedgeRatioCard = [
      getHedgeRatioCard({
        riskDataPerPeriod: cashflowsRisksData.perPeriod,
        hedgeRatioSettings: userEntity.riskSettingHedgeRatio,
        history,
        currency: currencyCode ?? 'all',
        cashflowAtRisk,
        cashflowAtRiskPoSo,
        cashflowAtRiskCustomCashflow: cashflowAtRiskCustomCashflow,
      }),
    ].filter(notNullTypeGuard);
    const budgetRatesCard = [
      getBudgetRatesCard({
        riskDataPerPeriod: cashflowsRisksData.perPeriod,
        history,
        currency: currencyCode ?? 'all',
        cashflowAtRisk,
        cashflowAtRiskPoSo,
        cashflowAtRiskCustomCashflow: cashflowAtRiskCustomCashflow,
      }),
    ].filter(notNullTypeGuard);

    const cards: TReduceRisksCard[] = [
      getCashBalancesCard({
        history,
        currency: currencyCode ?? 'all',
        cashflowAtRisk,
        cashflowAtRiskCash,
        balances,
        externalBalances,
        hasUpdateBalancesPermission,
      }),
      {
        id: 2,
        icon: 'risk-book-ico',
        title: 'Book FX for overdue/upcoming invoices',
        textOne: `You have overdue or upcoming invoices in ${currencyCode}.`,
        textTwo: `Booking FX for upcoming invoices turns them into fixed cashflows in ${entityCurrencyCode}${
          cashflowAtRiskPayablesReceivables14 > 0
            ? ` and can reduce risks by up to ${parseIntoShortNumberString(
                cashflowAtRiskPayablesReceivables14
              )}`
            : ''
        }.`,
        buttonText: 'Convert upcoming invoices',
        onClick: () => history.push('/app/risks/hedge-invoices'),
        showRisk: true,
        risk: getReduceRiskRatingForHexagons(
          cashflowAtRiskPayablesReceivables14 / cashflowAtRisk
        ),
        disabled: !hasCreatePrebooksPermission,
      },
      {
        id: 3,
        icon: 'risk-fix-fx-ico',
        title: 'Fix FX for net receivables/payables',
        textOne: `${roundToPrecision(
          (cashflowAtRiskPayablesReceivables14Plus / cashflowAtRisk) * 100
        )}% of your risks comes from future cashflows expected after 14 days.`,
        textTwo:
          'You can remove risks by booking current rates for the net exposure.',
        buttonText: 'Prebook for net AP/ARs ',
        onClick: () =>
          history.push(
            getPrebookPageLink({
              predefinedBuyCurrency: currencyCode ?? undefined,
            })
          ),
        showRisk: true,
        risk: getReduceRiskRatingForHexagons(
          cashflowAtRiskPayablesReceivables14Plus / cashflowAtRisk
        ),
        disabled: !hasCreatePrebooksPermission,
      },
      ...hedgeRatioCard,
      ...budgetRatesCard,
      {
        id: 6,
        icon: 'support-ico',
        title: 'Schedule a consultation',
        textOne: 'Unsure what to do - talk to our experts.',
        textTwo:
          'We have helped 100s of blue-chip corporations worldwide before starting HedgeFlows.',
        buttonText: 'Schedule',
        onClick: () => openInNewTab(SCHEDULE_MEET_URL),
        showRisk: false,
      },
    ];

    return cards;
  }, [
    cashflowRisksByCurrency,
    cashflowsRisksData.perPeriod,
    currencyCode,
    entityCurrencyCode,
    hasCreatePrebooksPermission,
    hasUpdateBalancesPermission,
    history,
    userEntity.riskSettingHedgeRatio,
  ]);

  return {
    cards,
    isLoading: !cashflowRisksByCurrency,
    cashflowRisksByCurrency,
  };
};

export default useReduceRisks;
