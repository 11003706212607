import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  UseFormMethods,
} from 'react-hook-form';
import { useStoreState } from 'state';
import {
  IContact,
  ICountry,
  ICurrency,
  IPurpose,
  IRecipientFieldInstructions,
  Nullable,
  RECIPIENT_STATUS,
  ROUTING_TYPE,
  SWIFT_TYPE,
} from 'types';
import CreatableSelectMenu from '../CreatableSelectMenu/CreatableSelectMenu';
import StaleCheckboxControlled from '../StaleCheckboxControlled/StaleCheckboxControlled';
import StaleInfo from '../StaleInfo/StaleInfo';
import StaleInput from '../StaleInput/StaleInput';
import StaleInputIban from '../StaleInputIban/StaleInputIban';
import StaleInputRadioNew from '../StaleInputRadioNew/StaleInputRadioNew';
import StaleInputRoutingNumber from '../StaleInputRoutingNumber/StaleInputRoutingNumber';
import StaleInputSelect from '../StaleInputSelect/StaleInputSelect';
import StaleInputSwift from '../StaleInputSwift/StaleInputSwift';
import { Paragraph, Subtitle } from '../Typography/Typography';
import { onValidateEmails } from '../CreatableSelectMenu/utils';
import { CreatableSelectMenuOption } from '../CreatableSelectMenu/types';
import { AddContactInputs } from './AddContactForm';
import { Row } from '../Row/Row';
import { useTheme } from 'styled-components';
import { Col } from '../Col/Col';

export const useGenerateField = ({
  recipientForEdit,
  watch,
  control,
  setValue,
  errors,
  validateOnMount,
  swiftBankData,
  setSwiftBankData,
  setIsSwiftInputFocused,
  ibanBankData,
  setIbanBankData,
  routingNumberBankData,
  setRoutingNumberBankData,

  orderedCountries,
  countriesSearchValue,
  recipientCountry,
  currenciesSearchValue,
  setCurrenciesSearchValue,

  disableCurrency,
  setCountriesSearchValue,

  setAccountCountry,

  swiftOurs,
  swiftShared,

  sellCurrency,

  isPurposesLoading,
  purposeValue,
  purposesToUse,

  emails,

  setRoutingType,
  setRoutingType2,
}: {
  control: Control;
  watch: UseFormMethods['watch'];
  setValue: UseFormMethods['setValue'];
  errors: DeepMap<AddContactInputs, FieldError>;
  emails: CreatableSelectMenuOption[];
  recipientForEdit: Nullable<IContact | Partial<IContact>>;
  validateOnMount: boolean;
  swiftBankData: any;
  setSwiftBankData: React.Dispatch<any>;
  setIsSwiftInputFocused: React.Dispatch<React.SetStateAction<boolean>>;
  ibanBankData: any;
  setIbanBankData: React.Dispatch<any>;
  routingNumberBankData: any;
  setRoutingNumberBankData: React.Dispatch<any>;
  orderedCountries: ICountry[];
  countriesSearchValue: string;
  recipientCountry: ICountry;
  currenciesSearchValue: string;
  setCurrenciesSearchValue: React.Dispatch<React.SetStateAction<string>>;
  disableCurrency: boolean;
  setCountriesSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setAccountCountry: React.Dispatch<React.SetStateAction<string | undefined>>;
  swiftOurs: number | null;
  swiftShared: number | null;
  sellCurrency: ICurrency;
  isPurposesLoading: boolean;
  purposeValue: {
    name: IPurpose['description'];
    id: IPurpose['description'];
    value: IContact['purpose'];
  };
  purposesToUse: any;
  setRoutingType: React.Dispatch<React.SetStateAction<ROUTING_TYPE | null>>;
  setRoutingType2: React.Dispatch<React.SetStateAction<ROUTING_TYPE | null>>;
}) => {
  const theme = useTheme();
  const { currencies } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );

  const generateField = (field: IRecipientFieldInstructions) => {
    if (
      (field.readOnly && !recipientForEdit) ||
      (!watch(field.name) && field.hideIfEmpty)
    ) {
      return null;
    }

    switch (field.type) {
      case 'IBAN':
        return (
          <StaleInputIban
            id={field.name}
            view="moving"
            label={field.title}
            defaultValue={''}
            validateOnMount={
              recipientForEdit && validateOnMount ? !!field.required : false
            }
            name={field.name}
            control={control}
            rules={{
              required: field.required,
              ...(field.minLength
                ? {
                    minLength: {
                      value: field.minLength,
                      message: `Should have min length of ${field.minLength}`,
                    },
                  }
                : {}),
              ...(field.maxLength
                ? {
                    maxLength: {
                      value: field.maxLength,
                      message: `Should have max length of ${field.maxLength}`,
                    },
                  }
                : {}),
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
            onGetBankDataCallback={setIbanBankData}
            renderIcon={
              field.tooltip ? (
                <StaleInfo mode="hover" strategy="fixed" placement="top">
                  <Paragraph color="white">{field.tooltip}</Paragraph>
                </StaleInfo>
              ) : null
            }
            swiftCountryCode={swiftBankData?.bankCountry_ISO}
          />
        );
      case 'BICSWIFT':
        return (
          <StaleInputSwift
            id={field.name}
            view="moving"
            label={field.title}
            defaultValue={''}
            name={field.name}
            control={control}
            onChangeCallback={(e) => {
              setValue(field.name, e.target.value.toUpperCase().trim());
            }}
            rules={{
              required: field.required,
              ...(field.minLength
                ? {
                    minLength: {
                      value: field.minLength,
                      message: `Should have min length of ${field.minLength}`,
                    },
                  }
                : {}),
              ...(field.maxLength
                ? {
                    maxLength: {
                      value: field.maxLength,
                      message: `Should have max length of ${field.maxLength}`,
                    },
                  }
                : {}),
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
            onGetBankDataCallback={setSwiftBankData}
            renderIcon={
              field.tooltip ? (
                <StaleInfo mode="hover" strategy="fixed" placement="top">
                  <Paragraph color="white">{field.tooltip}</Paragraph>
                </StaleInfo>
              ) : null
            }
            onFocus={() => setIsSwiftInputFocused(true)}
            onBlur={() => setIsSwiftInputFocused(false)}
            ibanCountryCode={ibanBankData?.bankCountry_ISO}
            routingNumberCountryCode={routingNumberBankData?.bankCountry_ISO}
            validateOnMount={
              recipientForEdit && validateOnMount ? !!field.required : false
            }
          />
        );
      case 'ROUTING':
        return (
          <StaleInputRoutingNumber
            id={field.name}
            view="moving"
            label={field.title}
            defaultValue={''}
            name={field.name}
            validateOnMount={
              recipientForEdit && validateOnMount ? !!field.required : false
            }
            control={control}
            onChangeCallback={() =>
              setRoutingType((field.routingType as ROUTING_TYPE) || null)
            }
            rules={{
              required: field.required,
              ...(field.minLength
                ? {
                    minLength: {
                      value: field.minLength,
                      message: `Should have min length of ${field.minLength}`,
                    },
                  }
                : {}),
              ...(field.maxLength
                ? {
                    maxLength: {
                      value: field.maxLength,
                      message: `Should have max length of ${field.maxLength}`,
                    },
                  }
                : {}),
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
            onGetBankDataCallback={setRoutingNumberBankData}
            renderIcon={
              field.tooltip ? (
                <StaleInfo mode="hover" strategy="fixed" placement="top">
                  <Paragraph color="white">{field.tooltip}</Paragraph>
                </StaleInfo>
              ) : null
            }
            routingType={field.routingType ?? ''}
            swiftCountryCode={swiftBankData?.bankCountry_ISO}
          />
        );
      case 'ROUTING2':
        return (
          <StaleInputRoutingNumber
            id={field.name}
            view="moving"
            label={field.title}
            defaultValue={''}
            name={field.name}
            validateOnMount={
              recipientForEdit && validateOnMount ? !!field.required : false
            }
            control={control}
            onChangeCallback={() =>
              setRoutingType2((field.routingType as ROUTING_TYPE) || null)
            }
            rules={{
              required: field.required,
              ...(field.minLength
                ? {
                    minLength: {
                      value: field.minLength,
                      message: `Should have min length of ${field.minLength}`,
                    },
                  }
                : {}),
              ...(field.maxLength
                ? {
                    maxLength: {
                      value: field.maxLength,
                      message: `Should have max length of ${field.maxLength}`,
                    },
                  }
                : {}),
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
            onGetBankDataCallback={setRoutingNumberBankData}
            renderIcon={
              field.tooltip ? (
                <StaleInfo mode="hover" strategy="fixed" placement="top">
                  <Paragraph color="white">{field.tooltip}</Paragraph>
                </StaleInfo>
              ) : null
            }
            routingType={field.routingType ?? ''}
            swiftCountryCode={swiftBankData?.bankCountry_ISO}
          />
        );
      case 'COUNTRY':
        return (
          <Controller
            id={field.name}
            name={field.name}
            control={control}
            defaultValue={null}
            rules={{
              required: field.required,
              minLength: {
                value: field.minLength ?? 0,
                message: `Should have min length of ${field.minLength}`,
              },
              maxLength: {
                value: field.maxLength ?? 0,
                message: `Should have max length of ${field.maxLength}`,
              },
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            render={({ onChange, name }) => {
              return (
                <StaleInputSelect
                  id={name}
                  name={name}
                  label={field.title}
                  view="moving"
                  data={orderedCountries
                    .filter((item) =>
                      item.name
                        .toLowerCase()
                        .trim()
                        .includes(countriesSearchValue.toLowerCase().trim())
                    )
                    .map((item) => ({
                      name: item.name,
                      id: item.name,
                      icon: item.alpha2.toLowerCase(),
                      value: { ...item, id: item.alpha2.toUpperCase() },
                    }))}
                  selected={
                    recipientCountry
                      ? {
                          name: recipientCountry.name,
                          id: recipientCountry.name,
                          icon: recipientCountry.alpha2.toLowerCase(),
                          value: {
                            ...recipientCountry,
                            id: recipientCountry.alpha2.toUpperCase(),
                          },
                        }
                      : null
                  }
                  onSelect={(item) => {
                    onChange(item.value);
                  }}
                  withSearch
                  searchValue={countriesSearchValue}
                  onSearch={(e) =>
                    setCountriesSearchValue(e.currentTarget.value)
                  }
                  onClose={() => setCountriesSearchValue('')}
                  strategy="fixed"
                  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  error={errors[field.name]?.message}
                />
              );
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
          />
        );
      case 'CURRENCY':
        return (
          <Controller
            id={field.name}
            name={field.name}
            control={control}
            rules={{
              required: field.required,
              ...(field.minLength
                ? {
                    minLength: {
                      value: field.minLength,
                      message: `Should have min length of ${field.minLength}`,
                    },
                  }
                : {}),
              ...(field.maxLength
                ? {
                    maxLength: {
                      value: field.maxLength,
                      message: `Should have max length of ${field.maxLength}`,
                    },
                  }
                : {}),
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            render={({ onChange, value, name }) => {
              return (
                <StaleInputSelect
                  id={name}
                  name={name}
                  label={field.title}
                  view="moving"
                  data={currencies
                    .filter(
                      (item) =>
                        item.name
                          .toLowerCase()
                          .trim()
                          .includes(
                            currenciesSearchValue.toLowerCase().trim()
                          ) ||
                        item.code
                          .toLowerCase()
                          .trim()
                          .includes(
                            currenciesSearchValue.toLowerCase().trim()
                          ) ||
                        item.country
                          .toLowerCase()
                          .trim()
                          .includes(currenciesSearchValue.toLowerCase().trim())
                    )
                    .map((item) => ({
                      name: item.id ?? '',
                      id: item.id ?? '',
                      icon: item.countryCode,
                      value: item,
                    }))}
                  // TODO: replace later when we use Add Contact not just for invoices
                  selected={value}
                  onSelect={(item) => {
                    // We need to reset the data after a new currency is selected
                    setSwiftBankData(null);
                    setIbanBankData(null);
                    setRoutingNumberBankData(null);
                    setAccountCountry(undefined);

                    onChange(item);
                  }}
                  withSearch
                  searchValue={currenciesSearchValue}
                  onSearch={(e) =>
                    setCurrenciesSearchValue(e.currentTarget.value)
                  }
                  onClose={() => setCurrenciesSearchValue('')}
                  strategy="fixed"
                  disabled={
                    disableCurrency ||
                    (!!recipientForEdit &&
                      recipientForEdit.status !== RECIPIENT_STATUS.draft)
                  }
                />
              );
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
          />
        );
      case 'SWIFTTYPE':
        return (
          <Col justify-content="flex-start" gap={theme.spacing.xxs}>
            <Row gap={theme.spacing.xs} justifyContent="flex-start">
              <Subtitle variant="bold">{field.title}</Subtitle>
              <StaleInfo mode="hover" strategy="fixed" placement="top">
                <Subtitle color="white" variant="bold">
                  SWIFT charges
                </Subtitle>
                <Paragraph color="white" mb variant="bold">
                  Shared
                </Paragraph>
                <Paragraph color="white">
                  The intermediary bank charges are deducted from the payment
                  amount. The payment amount received in the beneficiary bank
                  account may be less than the full amount expected.
                </Paragraph>

                <Paragraph mb mt variant="bold" color="white">
                  Ours
                </Paragraph>
                <Paragraph color="white">
                  The intermediary bank charges are covered by us and not
                  deducted from the payment amount. The beneficiary bank
                  receives the full payment amount.
                </Paragraph>
              </StaleInfo>
            </Row>

            <Controller
              name={field.name}
              control={control}
              defaultValue={SWIFT_TYPE.shared}
              render={({ onChange, value }) => (
                <Row gap={theme.spacing.m} justifyContent="flex-start">
                  <StaleInputRadioNew
                    id="shared"
                    label={`Shared | ${sellCurrency.symbol}${swiftShared}`}
                    checked={value === SWIFT_TYPE.shared}
                    onChange={() => onChange(SWIFT_TYPE.shared)}
                  />

                  <StaleInputRadioNew
                    id="ours"
                    label={
                      swiftOurs
                        ? `Ours | ${sellCurrency.symbol}${swiftOurs}`
                        : `Ours | Not Available`
                    }
                    checked={value === SWIFT_TYPE.ours}
                    onChange={() => onChange(SWIFT_TYPE.ours)}
                    disabled={!swiftOurs}
                  />
                </Row>
              )}
            />
          </Col>
        );
      case 'PURPOSE':
        return (
          <Controller
            id={field.name}
            name={field.name}
            control={control}
            defaultValue={null}
            rules={{
              required: field.required,
            }}
            render={({ onChange, name }) => (
              <StaleInputSelect
                id={name}
                name={name}
                label={field.title}
                view="moving"
                disabled={isPurposesLoading}
                data={purposesToUse}
                selected={purposeValue}
                onSelect={(item) => onChange(item)}
                // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                error={errors[field.name]?.message}
              />
            )}
          />
        );
      case 'RADIO':
        return (
          <StaleCheckboxControlled
            id="shouldSendRemittance"
            name="shouldSendRemittance"
            defaultValue={false}
            disabled={!emails.length}
            control={control}
            Label={<>Send remittance emails</>}
          />
        );

      default:
        if (field.name === 'contactEmail') {
          return (
            <Controller
              name="emails"
              control={control}
              rules={{
                validate: onValidateEmails,
              }}
              render={({ value, name, onChange }) => (
                <CreatableSelectMenu
                  name={name}
                  label={field.title}
                  data={value}
                  onChange={onChange}
                  // @ts-expect-error - Property 'message' does not exist on type '(DeepMap<CreatableSelectOption, FieldError> | undefined)[]'
                  error={errors.emails?.message}
                />
              )}
            />
          );
        }
        return (
          <StaleInput
            id={field.name}
            view="moving"
            label={field.title}
            name={field.name}
            control={control}
            disabled={
              field.readOnly ||
              (field.name === 'recipientName' &&
                !!recipientForEdit &&
                recipientForEdit.status !== RECIPIENT_STATUS.draft)
            }
            validateOnMount={
              recipientForEdit && validateOnMount ? !!field.required : false
            }
            onChangeCallback={
              field.name === 'accountNumber'
                ? (e) => setValue(field.name, e.target.value.toUpperCase())
                : undefined
            }
            rules={{
              required: field.required,
              ...(field.minLength
                ? {
                    minLength: {
                      value: field.minLength,
                      message: `Should have min length of ${field.minLength}`,
                    },
                  }
                : {}),
              ...(field.maxLength
                ? {
                    maxLength: {
                      value: field.maxLength,
                      message: `Should have max length of ${field.maxLength}`,
                    },
                  }
                : {}),
              pattern: {
                value: new RegExp(field.pattern ?? ''),
                message: `Format is incorrect.${
                  field.example ? ` Example: ${field.example}` : ''
                }`,
              },
            }}
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            error={errors[field.name]?.message}
            renderIcon={
              field.tooltip ? (
                <StaleInfo mode="hover" strategy="fixed" placement="top">
                  <Paragraph color="white">{field.tooltip}</Paragraph>
                </StaleInfo>
              ) : null
            }
          />
        );
    }
  };

  return generateField;
};
