import { FC } from 'react';
import AddContactForm from 'components/shared/AddContactForm/AddContactForm';
import { TRANSFER_TYPE } from 'types';
import { useStoreState } from 'state';

const AddContactPage: FC = () => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );

  const initialCurrency = currencyByCode('USD');
  const sellCurrency = currencyByCode(systemVariables?.defaultSellCurrency);
  if (!sellCurrency || !initialCurrency) {
    return null;
  }

  return (
    <AddContactForm
      initialCurrency={initialCurrency}
      sellCurrency={sellCurrency}
      initialTransferType={TRANSFER_TYPE.priority}
      onClose={() => {}}
    />
  );
};

export default AddContactPage;
