import { FC } from 'react';
import StalePopup from 'components/shared/StalePopup/StalePopup';
import { ResponsiveCenteredContainer } from 'components/Layout/Layout.styles';
import Button from 'components/shared/Button/Button';
import StaleBtnGroup from 'components/shared/StaleBtnGroup/StaleBtnGroup';

interface OwnProps {
  onClose: () => void;
  onSubmit: () => void;
  disabled: boolean;
}

const DirtyPopup: FC<OwnProps> = ({ onClose, onSubmit, disabled }) => (
  <StalePopup
    title="You have unsaved changes, do you want to:"
    theme="small"
    width="347px"
    minHeight="auto"
    onClose={onClose}
  >
    <ResponsiveCenteredContainer style={{ padding: 0 }}>
      <StaleBtnGroup horizontal container={false}>
        <Button disabled={disabled} onClick={onSubmit}>
          Save
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Ignore
        </Button>
      </StaleBtnGroup>
    </ResponsiveCenteredContainer>
  </StalePopup>
);

export default DirtyPopup;
