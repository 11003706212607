import { FC, useMemo, useState, useEffect } from 'react';
import {
  Row,
  Subtitle,
  Title,
  StaleRecipientDropdown,
  Icon,
  Col,
} from 'components';
import TransferSummaryInfo from 'components/shared/TransferSummaryInfo/TransferSummaryInfo';
import { useStoreState } from 'state';
import {
  FirstStepValues,
  SecondStepValues,
} from 'pages/SimpleTransfer/SimpleTransfer';
import InputSelect from 'components/shared/InputSelect/InputSelect';
import {
  RECIPIENT_STATUS,
  IContact,
  IRecipient,
  Nullable,
  PAYMENT_TYPE,
  TRANSFER_TYPE,
} from 'types';
import {
  RecipientOptionText,
  RecipientOptionWrapper,
} from './Recipient.styles';
import { StaleField } from 'components/shared/StaleField/StaleField.styles';
import { RECIPIENT_TYPE } from 'types/recipients';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import Button from 'components/shared/Button/Button';
import { useTheme } from 'styled-components';
import useUrlValues from 'hooks/useUrlValues';
import useInvoice from 'hooks/useInvoice';
import useGetExternalInvoiceRecipient from 'hooks/useExternalInvoiceContactOrContactOnInvoice';
import AddContactForm from 'components/shared/AddContactForm/AddContactForm';

interface OwnProps {
  onContinue: (values: SecondStepValues) => void;
  firstStepValues: FirstStepValues;
}

const Recipient: FC<OwnProps> = ({ onContinue, firstStepValues }) => {
  const theme = useTheme();
  const { sellCurrency, sellAmount } = firstStepValues;
  const { getPriorityFee } = useStoreState((state) => state.ReferenceDataState);
  const { recipients } = useStoreState((state) => state.RecipientsState);
  const { invoiceId } = useUrlValues('invoiceId');

  const { invoice, invoiceRecipient } = useInvoice(invoiceId);
  const {
    isLoadingInvoiceOrContact,
    recipientFromInvoice,
  } = useGetExternalInvoiceRecipient(invoice);

  const [recipient, setRecipient] = useState<Nullable<IContact>>(null);
  const [recipientsSearchValue, setRecipientsSearchValue] = useState('');
  const [recipientForEdit, setRecipientForEdit] = useState<IRecipient | null>(
    null
  );
  const [isAddRecipient, setIsAddRecipient] = useState(false);

  const fee =
    (recipient &&
      recipient.paymentType === PAYMENT_TYPE.swift &&
      recipient.swiftType &&
      getPriorityFee(
        recipient.swiftType,
        sellCurrency.code,
        recipient.accountCountry
      )) ||
    0;

  const onAddRecipient = () => {
    if (recipientFromInvoice && !recipientFromInvoice.enabled) {
      setRecipientForEdit(recipientFromInvoice);
    } else if (invoiceRecipient && !invoiceRecipient.enabled) {
      setRecipientForEdit(invoiceRecipient);
    }
    setIsAddRecipient(true);
  };

  const recipientsFilteredByCurrency = useMemo(
    () =>
      recipients.filter(
        (item) =>
          item.currency === sellCurrency.code &&
          item.status !== RECIPIENT_STATUS.draft &&
          !item.shouldPausePayments
      ),
    [recipients, sellCurrency.code]
  );

  const showDropdown = recipientsFilteredByCurrency?.length > 5;

  useEffect(() => {
    if (
      recipientFromInvoice &&
      recipientFromInvoice.enabled &&
      !recipientFromInvoice.shouldPausePayments
    ) {
      setRecipient(recipientFromInvoice);
    } else if (
      invoiceRecipient &&
      invoiceRecipient.enabled &&
      !invoiceRecipient.shouldPausePayments
    ) {
      setRecipient(invoiceRecipient);
    }
  }, [invoiceRecipient, recipientFromInvoice]);

  return (
    <FlowStepWrapper>
      <FlowStepContent>
        <FlowContentLeft>
          <Row>
            <Title variant="h5">Select the recipient</Title>
            <Button
              variant="link"
              type="button"
              onClick={onAddRecipient}
              icon="user-plus-ico"
              ml
              mlValue={theme.spacing.xs}
            >
              Add new recipient
            </Button>
          </Row>

          {!recipientsFilteredByCurrency?.length && (
            <Subtitle style={{ textAlign: 'center', marginTop: 24 }}>
              There are no recipients
            </Subtitle>
          )}

          {showDropdown ? (
            <StaleField>
              <InputSelect<IRecipient>
                inputProps={{
                  id: 'recipient',
                  view: 'moving',
                  label: 'Search by Company name',
                }}
                data={recipientsFilteredByCurrency.filter((item) =>
                  item.recipientName
                    .toLocaleLowerCase()
                    .includes(recipientsSearchValue.toLocaleLowerCase())
                )}
                withSearch
                selected={recipient}
                selectedValueLabel={recipient?.recipientName}
                labelKey="recipientName"
                onSelect={(item) => setRecipient(item)}
                onSearch={setRecipientsSearchValue}
                renderOption={(item) => (
                  <RecipientOptionWrapper>
                    <Icon
                      icon={
                        item.recipientEntityType === RECIPIENT_TYPE.company
                          ? 'briefcase-ico'
                          : 'user-ico'
                      }
                    />
                    <RecipientOptionText>
                      {item.recipientName}
                    </RecipientOptionText>
                  </RecipientOptionWrapper>
                )}
              />
            </StaleField>
          ) : (
            <Col mt>
              {recipientsFilteredByCurrency.map((item) => (
                <StaleRecipientDropdown
                  key={item.id}
                  selectedRecipient={recipient}
                  transferType={
                    recipient?.paymentType !== PAYMENT_TYPE.swift
                      ? TRANSFER_TYPE.regular
                      : TRANSFER_TYPE.priority
                  }
                  recipient={item}
                  sellCurrency={sellCurrency}
                  setRecipient={setRecipient}
                />
              ))}
            </Col>
          )}

          {showDropdown && recipient && (
            <Col mt>
              <StaleRecipientDropdown
                selectedRecipient={recipient}
                transferType={
                  recipient?.paymentType !== PAYMENT_TYPE.swift
                    ? TRANSFER_TYPE.regular
                    : TRANSFER_TYPE.priority
                }
                recipient={recipient}
                sellCurrency={sellCurrency}
                setRecipient={setRecipient}
              />
            </Col>
          )}
        </FlowContentLeft>

        <FlowContentRight>
          <Subtitle variant="bold">Summary</Subtitle>

          <TransferSummaryInfo
            sellCurrency={sellCurrency}
            sellAmount={sellAmount}
            recipient={recipient}
            fee={fee}
          />
        </FlowContentRight>
      </FlowStepContent>

      <FlowStepFooter>
        <Button
          onClick={() => {
            if (recipient && recipient.status !== RECIPIENT_STATUS.draft) {
              onContinue({
                recipient,
                fee,
                invoice,
              });
            }
          }}
          disabled={
            !recipient ||
            isLoadingInvoiceOrContact ||
            recipient?.status === RECIPIENT_STATUS.draft ||
            recipient.shouldPausePayments
          }
        >
          Continue
        </Button>
      </FlowStepFooter>

      {isAddRecipient && (
        <AddContactForm
          isPopup
          onClose={() => setIsAddRecipient(false)}
          withSaveAsDraft={false}
          sellCurrency={sellCurrency}
          initialCurrency={sellCurrency}
          initialTransferType={TRANSFER_TYPE.priority}
          setRecipient={setRecipient}
          onContinue={() => {
            setIsAddRecipient(false);
          }}
          recipientForEdit={recipientForEdit || null}
          disableCurrency
        />
      )}
    </FlowStepWrapper>
  );
};

export default Recipient;
